<template>
  <div>
    <!--phần header-->
    <button-all-header
      :contentBtnAdd="'Thêm vị trí'"
      :arrayExcel="[]"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDelete="deleteItems"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />
    <!--Phần Bảng -->
    <vue-good-table
      ref="user-table"
      :columns="columns"
      :rows="dataTable || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'code'">
          <b-badge variant="light-primary">
            {{ props.row.code }}
          </b-badge>
        </span>
        <!-- Chức năng -->
        <span v-else-if="props.column.field == 'action'">
          <feather-icon
            icon="EditIcon"
            size="18"
            class="text-body mr-1"
            @click="showModalEdit(props.row.id)"
          />
          <feather-icon
            icon="Trash2Icon"
            size="18"
            class="text-body"
            @click="deleteItem(props.row.id)"
          />
        </span>
      </template>
    </vue-good-table>
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="handlePageClick"
    />

    <!-- modal xóa-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <edit-weather
      :id="modalCreateClimate"
      :type="modalType"
      :title="modalType === 'add' ? 'Thêm yếu tố khí hậu' : 'Cập nhật yếu tố khí hậu'"
      :dataClimateId="dataClimateId"
      @handleCreateClimate="handleCreateClimate"
    />
  </div>
</template>

<script>
import {
  BBadge,
} from 'bootstrap-vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import EditWeather from './modal-create/EditWeather.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    ConfirmModal,
    BBadge,
    EditWeather,
  },
  data() {
    return {
      totalRecord: 0,
      dataTable: [],
      columns: [
        {
          label: 'VỊ TRÍ QUAN TRẮC',
          field: 'position',
          sortable: false,
        },
        {
          label: 'NHIỆT ĐỘ SỐ MẪU ĐẠT',
          field: 'temperatureReachedOk',
          sortable: false,
        },
        {
          label: 'NHIỆT ĐỘ SỐ MẪU KHÔNG ĐẠT',
          field: 'temperatureReachedNot',
          sortable: false,
        },
        {
          label: 'ĐỘ ẨM SỐ MẪU ĐẠT',
          field: 'humidityReachedOk',
          sortable: false,
        },
        {
          label: 'ĐỘ ẨM SỐ MẪU KHÔNG ĐẠT',
          field: 'humidityReachedNot',
          sortable: false,
        },
        {
          label: 'TỐC ĐỘ GIÓ SỐ MẪU ĐẠT',
          field: 'windSpeedReachedOk',
          sortable: false,
        },
        {
          label: 'TỐC ĐỘ GIÓ SỐ MẪU KHÔNG ĐẠT',
          field: 'windSpeedReachedNot',
          sortable: false,
        },
        {
          label: 'BỨC XẠ NHIỆT SỐ MẪU ĐẠT',
          field: 'heatRadiationReachedOk',
          sortable: false,
        },
        {
          label: 'BỨC XẠ NHIỆT SỐ MẪU KHÔNG ĐẠT',
          field: 'heatRadiationReachedNot',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          sortable: false,
        },
      ],
      urlQuery: {
        observationMonitorId: this.$route.params.id,
        search: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      modalCreateClimate: 'comfirm-create-climate',
      deleteIds: [],
      showBtnMultiDelete: false,
      climateId: null,
      modalType: null,
      dataClimateId: {},
    }
  },
  created() {
    this.fetchDataListClimate(this.urlQuery)
  },
  methods: {
    // Lấy danh sách quan trắc người lao động
    async fetchDataListClimate(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_MONITOR_CLIMATE, {
        params: urlQuery,
      })
      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Mở modal tạo và chỉnh sửa kiểm định viên
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalCreateClimate)
    },

    // Mở modal tạo và chỉnh sửa kiểm định viên
    async showModalEdit(id) {
      this.modalType = 'edit'
      this.climateId = id
      this.$bvModal.show(this.modalCreateClimate)
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_MONITOR_CLIMATE}${this.climateId}`)
      this.dataClimateId = data
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchDataListClimate(this.urlQuery)
    },

    // tìm kiếm lộ trình đào tạo
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        this.fetchDataListClimate(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        this.fetchDataListClimate(this.urlQuery)
      }
    },
    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.delete(ConstantsApi.DELETE_MONITOR_CLIMATE, {
        params: {
          model: JSON.stringify(this.deleteIds),
          observationMonitorId: this.$route.params.id,
        },
      }).then(res => {
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Xóa thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
        this.$bvModal.hide(this.modalCreateClimate)
        this.fetchDataListClimate(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Action tạo mới và chỉnh sửa cơ cấu tổ chức
    async handleCreateClimate(val) {
      const payload = {
        ...val,
        observationMonitorId: this.$route.params.id,
        temperatureReachedOk: parseInt(val.temperatureReachedOk, 10),
        temperatureReachedNot: parseInt(val.temperatureReachedNot, 10),
        humidityReachedOk: parseInt(val.humidityReachedOk, 10),
        humidityReachedNot: parseInt(val.humidityReachedNot, 10),
        windSpeedReachedOk: parseInt(val.windSpeedReachedOk, 10),
        windSpeedReachedNot: parseInt(val.windSpeedReachedNot, 10),
        heatRadiationReachedOk: parseInt(val.heatRadiationReachedOk, 10),
        heatRadiationReachedNot: parseInt(val.heatRadiationReachedNot, 10),
      }
      if (this.modalType === 'edit') {
        const data = {
          ...payload,
          id: this.climateId,
        }
        this.$showAllPageLoading()
        await axiosApiInstance.put(ConstantsApi.EDIT_MONITOR_CLIMATE, data).then(res => {
          if (res.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Cập nhật thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.fetchDataListClimate(this.urlQuery)
            this.$bvModal.hide(this.modalCreateClimate)
          } else {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        })
      }
      if (this.modalType === 'add') {
        this.$showAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.CREATE_MONITOR_CLIMATE, payload).then(res => {
          if (res.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Tạo thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalCreateClimate)
            this.fetchDataListClimate(this.urlQuery)
          } else {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
</style>

<template>
  <div>
    <!--phần header-->
    <button-all-header
      :contentBtnAdd="'Thêm vị trí'"
      :arrayExcel="[]"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDelete="deleteItems"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />
    <b-table-simple
      hover
      small
      caption-top
      responsive
      class="table-simple"
    >
      <colgroup><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col></colgroup>
      <b-thead>
        <b-tr>
          <b-th><b-form-checkbox
            @change="selectAllCheckbox($event)"
          /></b-th>
          <b-th colspan="1">
            VỊ TRÍ LAO ĐỘNG
          </b-th>
          <b-th colspan="1">
            Mức âm hoặc mức âm tương đương không quá dBA
          </b-th>
          <b-th colspan="8">
            Mức âm dB ở các dải ôc-ta với tần số trung bình nhân (Hz) không vượt quá dB
          </b-th>
          <b-th colspan="1">
            CHỨC NĂNG
          </b-th>
        </b-tr>
        <b-tr>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th>63</b-th>
          <b-th>125</b-th>
          <b-th>250</b-th>
          <b-th>500</b-th>
          <b-th>1000</b-th>
          <b-th>2000</b-th>
          <b-th>4000</b-th>
          <b-th>8000</b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="dataTable.length">
        <b-tr
          v-for="(item, index) in dataTable"
          :key="index"
        >
          <b-td class="pb-25">
            <b-form-checkbox
              :checked="checkId.includes(item.id)"
              @change="selectRowTable($event, item.id)"
            />
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.position }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.noiseDba }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.s63 }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.s125 }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.s250 }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.s500 }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.s1000 }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.s2000 }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.s4000 }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.s8000 }}
          </b-td>
          <b-td class="pb-1 pt-1">
            <feather-icon
              icon="Edit3Icon"
              size="18"
              class="text-body ml-2"
              @click="showModalEdit(item.id)"
            />
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body ml-2"
              @click="deleteItem(item.id)"
            />
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tbody
        v-else
      >
        <div class="empty-table d-block">
          <div class="w-100 d-flex align-items-center justify-content-center">
            <b-img
              class="img-empty-table"
              fluid
              :src="$emptyImg"
              alt="empty table"
            />
          </div>
        </div>
      </b-tbody>
    </b-table-simple>
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="handlePageClick"
    />

    <!-- modal xóa-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <!-- Modal tạo tiếng ồn -->
    <edit-noise
      :id="modalEditNoise"
      :type="modalType"
      :dataNoiseId="dataNoiseId"
      @handleCreateNoise="handleCreateNoise"
    />

  </div>
</template>

<script>
import {
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BThead,
  BFormCheckbox,
  BImg,
} from 'bootstrap-vue'

import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import EditNoise from './modal-create/EditNoise.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BThead,
    ButtonAllHeader,
    BFormCheckbox,
    EditNoise,
    ConfirmModal,
    MyPagination,
    BImg,
  },
  data() {
    return {
      dataTable: [],
      totalRecord: 0,
      urlQuery: {
        search: '',
        pageSize: 10,
        pageNumber: 1,
        observationMonitorId: this.$route.params.id,
      },
      showBtnMultiDelete: false,
      modalEditNoise: 'confirm-edit-noise',
      modalType: null,
      noiseId: null,
      dataNoiseId: {},
      confirmModalId: 'confirm-modal',
      modalContent: '',
      deleteIds: [],
      checkId: [],
    }
  },
  created() {
    this.fetchTableNoise(this.urlQuery)
  },
  methods: {
    async fetchTableNoise(urlQuery) {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_MONITOR_NOISE, {
        params: urlQuery,
      })
      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.showBtnMultiDelete = false
    },
    // Mở modal tạo và chỉnh sửa kiểm định viên
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalEditNoise)
    },

    // Mở modal tạo và chỉnh sửa kiểm định viên
    async showModalEdit(id) {
      this.modalType = 'edit'
      this.noiseId = id
      this.$bvModal.show(this.modalEditNoise)
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_MONITOR_NOISE}${this.noiseId}`)
      this.dataNoiseId = data
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchTableNoise(this.urlQuery)
    },

    // tìm kiếm lộ trình đào tạo
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        this.fetchTableNoise(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        this.fetchTableNoise(this.urlQuery)
      }
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(event, val) {
      if (event === true) {
        this.checkId.push(val)
      }
      if (event === false) {
        const a = this.checkId.findIndex(x => x === val)
        this.checkId.splice(a, 1)
      }
      if (this.checkId.length > 0) {
        this.showBtnMultiDelete = true
      } else if (this.checkId.length < 1 && event === false) {
        this.showBtnMultiDelete = false
      }
    },

    // Chọn xóa tất cả
    selectAllCheckbox(event) {
      if (event === true) {
        this.showBtnMultiDelete = true
        this.checkId = this.dataTable.map(element => element.id)
      } else if (event === false) {
        this.showBtnMultiDelete = false
        this.checkId = []
      }
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.checkId
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.delete(ConstantsApi.DELETE_MONITOR_NOISE, {
        params: {
          model: JSON.stringify(this.deleteIds),
          observationMonitorId: this.$route.params.id,
        },
      }).then(res => {
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Xóa thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
        this.$bvModal.hide(this.modalEditNoise)
        this.fetchTableNoise(this.urlQuery)
        this.checkId = []
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Action tạo mới và chỉnh sửa cơ cấu tổ chức
    async handleCreateNoise(val) {
      const payload = {
        ...val,
        observationMonitorId: this.$route.params.id,
        noiseDba: parseInt(val.noiseDba, 10),
        s63: parseInt(val.s63, 10),
        s125: parseInt(val.s125, 10),
        s250: parseInt(val.s250, 10),
        s500: parseInt(val.s500, 10),
        s1000: parseInt(val.s1000, 10),
        s2000: parseInt(val.s2000, 10),
        s4000: parseInt(val.s4000, 10),
        s8000: parseInt(val.s8000, 10),
      }
      if (this.modalType === 'edit') {
        const data = {
          ...payload,
          id: this.noiseId,
        }
        this.$showAllPageLoading()
        await axiosApiInstance.put(ConstantsApi.EDIT_MONITOR_NOISE, data).then(res => {
          if (res.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Cập nhật thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.fetchTableNoise(this.urlQuery)
            this.$bvModal.hide(this.modalEditNoise)
            this.checkId = []
          } else {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        })
      }
      if (this.modalType === 'add') {
        this.$showAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.CREATE_MONITOR_NOISE, payload).then(res => {
          if (res.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Tạo thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalEditNoise)
            this.fetchTableNoise(this.urlQuery)
            this.checkId = []
          } else {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
.table-simple {
  .table {
    thead {
      tr {
        th {
          background-color: rgba(34, 127, 244, 0.12) !important;
        }
      }
    }
  }
}
</style>

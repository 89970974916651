var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"size":"md","title":"Thêm vị trí","cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-title":"Lưu","centered":"","no-close-on-backdrop":""},on:{"ok":_vm.handleCreateLight,"hidden":_vm.resetState,"show":_vm.fetchDataCombobox}},[_c('validation-observer',{ref:"rule"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Vị trí trắc quan "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"position","rules":"required","custom-messages":{required: "Vị trí quan trắc là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.position),callback:function ($$v) {_vm.$set(_vm.dataInput, "position", $$v)},expression:"dataInput.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Loại quy chuẩn "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"lightingLevelId","rules":"required","custom-messages":{required: "Loại quy chuẩn là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('LightSelectUpdate',{model:{value:(_vm.dataInput.lightingLevelId),callback:function ($$v) {_vm.$set(_vm.dataInput, "lightingLevelId", $$v)},expression:"dataInput.lightingLevelId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Số mẫu đạt "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"reachedOk","rules":"required","custom-messages":{required: "Số mẫu đạt là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.reachedOk),callback:function ($$v) {_vm.$set(_vm.dataInput, "reachedOk", $$v)},expression:"dataInput.reachedOk"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Số mẫu không đạt "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"reachedNot","rules":"required","custom-messages":{required: "Số mẫu không đạt là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.reachedNot),callback:function ($$v) {_vm.$set(_vm.dataInput, "reachedNot", $$v)},expression:"dataInput.reachedNot"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"size":"lg","title":_vm.title,"cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-title":"Lưu","centered":"","no-close-on-backdrop":""},on:{"ok":_vm.handleCreateClimate,"hidden":_vm.resetState,"show":_vm.fetchDataCombobox}},[_c('validation-observer',{ref:"rule"},[_c('b-form',{staticClass:"content-form"},[_c('h3',{staticClass:"d-flex align-items-center mb-1"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"20","icon":"InfoIcon"}}),_c('span',[_vm._v(" Thông tin vị trí ")])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Vị trí quan trắc "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"position","rules":"required","custom-messages":{required: "Vị trí quan trắc là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.position),callback:function ($$v) {_vm.$set(_vm.dataInput, "position", $$v)},expression:"dataInput.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Loại quy chuẩn "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"temperatureLevelId","rules":"required","custom-messages":{required: "Loại quy chuẩn là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (title) { return title.key; },"label":"value","options":_vm.dataCombobox || [],"placeholder":'Lựa chọn loại quy chuẩn',"state":errors.length > 0 ? false:null},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataInput.temperatureLevelId),callback:function ($$v) {_vm.$set(_vm.dataInput, "temperatureLevelId", $$v)},expression:"dataInput.temperatureLevelId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h3',{staticClass:"d-flex align-items-center mb-1"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"20","icon":"ThermometerIcon"}}),_c('span',[_vm._v(" Nhiệt độ ")])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Nhiệt độ số mẫu đạt "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"temperatureReachedOk","rules":"required","custom-messages":{required: "Nhiệt độ số mẫu đạt là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.temperatureReachedOk),callback:function ($$v) {_vm.$set(_vm.dataInput, "temperatureReachedOk", $$v)},expression:"dataInput.temperatureReachedOk"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Nhiệt độ số mẫu không đạt "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"temperatureReachedNot","rules":"required","custom-messages":{required: "Nhiệt độ số mẫu không đạt là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.temperatureReachedNot),callback:function ($$v) {_vm.$set(_vm.dataInput, "temperatureReachedNot", $$v)},expression:"dataInput.temperatureReachedNot"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h3',{staticClass:"d-flex align-items-center mb-1"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"20","icon":"CloudDrizzleIcon"}}),_c('span',[_vm._v(" Độ ẩm ")])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Độ ẩm số mẫu đạt "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"humidityReachedOk","rules":"required","custom-messages":{required: "Độ ẩm số mẫu đạt là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.humidityReachedOk),callback:function ($$v) {_vm.$set(_vm.dataInput, "humidityReachedOk", $$v)},expression:"dataInput.humidityReachedOk"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Độ ẩm số mẫu không đạt "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"humidityReachedNot","rules":"required","custom-messages":{required: "Độ ẩm số mẫu không đạt là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.humidityReachedNot),callback:function ($$v) {_vm.$set(_vm.dataInput, "humidityReachedNot", $$v)},expression:"dataInput.humidityReachedNot"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h3',{staticClass:"d-flex align-items-center mb-1"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"20","icon":"WindIcon"}}),_c('span',[_vm._v(" Tốc độ gió ")])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Tốc độ gió số mẫu đạt "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"windSpeedReachedOk","rules":"required","custom-messages":{required: "Tốc độ gió số mẫu đạt là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.windSpeedReachedOk),callback:function ($$v) {_vm.$set(_vm.dataInput, "windSpeedReachedOk", $$v)},expression:"dataInput.windSpeedReachedOk"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Tốc độ gió số mẫu không đạt "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"windSpeedReachedNot","rules":"required","custom-messages":{required: "Tốc độ gió số mẫu không đạt là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.windSpeedReachedNot),callback:function ($$v) {_vm.$set(_vm.dataInput, "windSpeedReachedNot", $$v)},expression:"dataInput.windSpeedReachedNot"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h3',{staticClass:"d-flex align-items-center mb-1"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"size":"20","icon":"ThermometerIcon"}}),_c('span',[_vm._v(" Bức xạ nhiệt ")])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Bức xạ nhiệt số mẫu đạt "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"heatRadiationReachedOk","rules":"required","custom-messages":{required: "Bức xạ nhiệt số mẫu đạt là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.heatRadiationReachedOk),callback:function ($$v) {_vm.$set(_vm.dataInput, "heatRadiationReachedOk", $$v)},expression:"dataInput.heatRadiationReachedOk"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Bức xạ nhiệt số mẫu không đạt "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"heatRadiationReachedNot","rules":"required","custom-messages":{required: "Bức xạ nhiệt số mẫu không đạt là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.heatRadiationReachedNot),callback:function ($$v) {_vm.$set(_vm.dataInput, "heatRadiationReachedNot", $$v)},expression:"dataInput.heatRadiationReachedNot"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }